<template>
  <div class="game-center">
    <div class="game-center__list">
      <div class="game-list">
        <div
          v-for="(game, index) of centerGames"
          :key="index"
          class="item"
          :class="{ 'game-list--top': game.class === 'final' }"
        >
          <GameItem :game="game" />
          <div v-if="jackpotNumber(game.link)" class="jackpot">
            <AnimateNumber :number="jackpotNumber(game.link)" show-coin />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '~/store/app'
import { getGameCenter } from '~/constants/home'
import { LOBBY_GAME_URLS } from '~/config/page-url'
const GameItem = defineAsyncComponent(() => import('~/components/mobile/pages/home/game-center/game-center-item.vue'))
const AnimateNumber = defineAsyncComponent(() => import('~/components/common/animate-number.vue'))

const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

const { HOME_SPORTS_MB } = getGameCenter()
const centerGames = reactive(HOME_SPORTS_MB)

const jackpotNumber = (link: string): number => {
  switch (link) {
    case LOBBY_GAME_URLS.NO_HU:
      return sumJackpot.value.jackpotNohu
    case LOBBY_GAME_URLS.BAN_CA:
      return sumJackpot.value.jackpotFishing
    case LOBBY_GAME_URLS.TABLE_GAME:
      return sumJackpot.value.jackpotIngame
    default:
      return 0
  }
}
</script>
<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/game-center/index.scss"></style>
